<template>
  <div class="flex">
    <input :id="fields.name" :name="fields.name" type="hidden" v-model="model" />
    <div class="flex items-center w-auto counter-wrapper">
      <div>
        <button :disabled="disabled" :class="`counter-button decrease relative ${buttonColor} circle ${fields.min != null && (disabled || model <= parseInt(fields.min) || model <= fields.min) ? 'disabled' : ''}`" v-on:click="onChange(-1)" />
      </div>
      <div class="flex px-2 font-bold text-center uppercase counter-content">
        <div :class="`counter-display ${fields.suffix ? 'with-suffix' : 'w-8'} text-center`" :data-suffix="fields.suffix ? getSuffix : null" :style="fields.suffix ? getWidth : null" v-html="model" />
      </div>
      <div>
        <button :disabled="disabled" :class="`counter-button increase relative ${buttonColor} circle ${disabled || maxed || (fields.max != null && model >= parseInt(fields.max)) ? 'disabled' : ''}`" v-on:click="onChange(1)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'disabled',
    'field',
    'model',
    'maxed',
    'color',
    'handleChange'
  ],
  computed: {
    buttonColor() {
      switch(this.color) {
        case 'purple':
          return 'bg-purple border-purple'
          break
        case 'white':
          return 'bg-white border-white'
          break
        case 'pink':
          return 'bg-pink border-pink'
          break
        default:
          return 'bg-black border-black'
      }
    },
    fields() {
      return this.field.fields || this.field
    },
    getWidth() {
      return {
        width: '100%'
      }
    },
    getSuffix() {
      if(this.model == 1 && this.field.fields.suffix.match(/\[(.*?)\]/)) {
        return this.field.fields.suffix.match(/\[(.*?)\]/)[1]
      } else {
        return this.field.fields.suffix.replace(/\[.*?\]/, '')
      }
    }
  },
  methods: {
    onChange(c) {
      this.handleChange(this.field, c)
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/app';
@import '../../assets/scss/_variables';

.counter-button {
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 50%;
  border-width: 3px;

  &.disabled {
    opacity: .25;
  }

  &:before, &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 6px);
    height: 2px;
    border-radius: 2px;
    background-color: white;
    transition: all .2s;
  }
  &:before {
    content: ' ';
    transform: translate(-50%, -50%);
  }
  &.increase:after {
    content: ' ';
    transform: translate(-50%, -50%) rotate(90deg);
  }
  &:hover {
    background-color: white;
    &:before, &:after {
      background-color: black;
    }
  }
  &.bg-purple:hover {
    &:before, &:after {
      background-color: $purple;
    }
  }
  &.bg-white {
    &:before, &:after {
      background-color: black;
    }
    &:hover {
      background-color: black;
      &:before, &:after {
        background-color: white;
      }
    }
  }
  &.bg-pink {
    background-color: $me-pink;
    &:before, &:after {
      background-color: white;
    }
    &:hover {
      background-color: white;
      &:before, &:after {
        background-color: $me-pink;
      }
    }
  }
  &.border-pink {
    border-color: $me-pink;
  }
}

.addon-info .counter-display {
  font-family: $decimal;
}
.counter-display.with-suffix {
  width: attr(data-width);
  &:after {
    content: attr(data-suffix);
    padding-left: .5rem;
  }
}

.counter {
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: $sm) {
    flex-direction: row;
  }
  > div {
    margin-left: auto;
    width: auto;
    justify-content: flex-end;
    @media (max-width: $sm) {
      margin-top: 5px
    }
  }

  .counter-wrapper {
    width: 100%;
  }

  .counter-content {
    width: 100%;
    justify-content: center;
  }
}
</style>
